import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../img/june-logo.svg';
import linesSVG from '../img/lines.svg';
import Contact from './Contact';

const AboutHeader = React.memo(() => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigateToAbout = () => {
    navigate('/about');
  };

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className="header">
      <img src={Logo} alt="Logo" className="header-logo" />
      <div className="header-items">
        <button onClick={toggleMenu} className={`header-button ${menuOpen ? 'active' : ''}`}>
          <img src={linesSVG} alt="Menu" />
        </button>
        {menuOpen && (
          <div className="menu">
            <button onClick={navigateToHome} className="menu-button">Home</button>
            <Contact/>
          </div>
        )}
      </div>
    </div>
  );
});

export default AboutHeader;