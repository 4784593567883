import { FilloutPopupEmbed } from "@fillout/react";
import "@fillout/react/style.css";
import React, { useState } from 'react';


const Contact = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className='menu-button'
                onClick={() => setIsOpen(true)}
            >
               Contact
            </button>
            {isOpen && (
                <FilloutPopupEmbed
                    filloutId="pf7dqz1Us1us"
                    onClose={() => setIsOpen(false)}
                />
            )}
        </>
    );
}

export default Contact;