import { FilloutPopupEmbed } from "@fillout/react";
import "@fillout/react/style.css";
import React, { useState } from 'react';

const SignupForm = React.memo(() => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <button
                className='beta-button'
                onClick={() => setIsOpen(true)}
            >
                Request early access
            </button>

            {isOpen && (
                <FilloutPopupEmbed
                    filloutId="rs3GFSngFSus"
                    onClose={() => setIsOpen(false)}
                />
            )}
        </>
    );
});

export default SignupForm;