import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './components/About';
import LandingPage from './components/LandingPage';
import Pitch from './components/Pitch';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import './styles/main.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="/pitch" element={<Pitch />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
