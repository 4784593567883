import React from 'react';
import ReactMarkdown from 'react-markdown';
import AboutHeader from './AboutHeader';

const aboutContent = `
# June: Your AI Productivity Companion

Welcome to **June**, the forefront of AI-powered productivity, meticulously engineered to seamlessly integrate cutting-edge technology into your daily workflow. Our mission is to simplify access to large language models (LLMs), ensuring an effortless and enjoyable productivity experience.

## Our Purpose
The proliferation of open-source LLMs and the widespread availability of foundational models present immense opportunities within the AI landscape. Yet, these models often fall short in addressing specific user needs. June is crafted to bridge these gaps, harnessing user feedback to continuously refine and customize our LLMs for practical, specialized applications.

## Our Vision
At June, our vision is to create an adaptive product that evolves in tandem with its users. By focusing on the unexploited potential within the LLM tech stack, we aspire to deliver an AI companion that is not only powerful but also exceptionally user-friendly.

## Our Solution
June is designed with simplicity at its core, requiring no elaborate setup or deep understanding of AI. Here’s how it works:

- **Path of Least Resistance:** Simply input your notes and ideas; June takes care of the rest.
- **LLM Recommendations:** Our sophisticated models analyze your inputs and suggest intelligent actions.
- **User Control:** Maintain complete autonomy—activate recommendations only when you choose to.
- **Continuous Improvement:** Every interaction fine-tunes our models, making June increasingly attuned to your preferences.

Join us in revolutionizing productivity through AI. Welcome to June.

For an in-depth look at June’s technical architecture and strategic roadmap, please refer to our [Pitch Deck](https://www.june.technology) or contact us at [tyler@june.technology](mailto:tyler@june.technology).
`;

const About = () => {
    return (
        <div>
            <AboutHeader />
            <div class="about-container">
                <div className="about">
                    <ReactMarkdown>{aboutContent}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
};

export default About;
