import React from 'react';

const Pitch = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRI1oSRlePSIU3HgF_cY6amaao2cT6Br_h_npGBpOnBqXtDLJb215wFUljSbnzKMGn7Md86UsCA25at/embed?start=true&loop=false&delayms=60000" frameborder="0" width="1440" height="839" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        </div>
    );
};

export default Pitch;